import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  fonts: {
    heading: 'Montserrat',
    body: 'Montserrat',
  },
  colors: {
    primary: {
      50: '#d9faff',
      100: '#adebff',
      200: '#7dddff',
      300: '#4dcfff',
      400: '#25c1fe',
      500: '#13a8e5',
      600: '#0082b3',
      700: '#005d81',
      800: '#003950',
      900: '#001520',
    },
    secondary: {
      50: '#ffe4f2',
      100: '#ffb4d4',
      200: '#fa84b5',
      300: '#f75497',
      400: '#f4267a',
      500: '#db0f60',
      600: '#ab084b',
      700: '#7b0335',
      800: '#4b0020',
      900: '#1e000d',
    },
  },
})

export default theme
