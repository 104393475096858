import { AppProps } from 'next/app'
import { DefaultSeo } from 'next-seo'
import { ChakraProvider, ColorModeProvider } from '@chakra-ui/react'

import theme from '../theme'

export default function MyApp({ Component, pageProps }: AppProps) {
  const title = 'Gaudia'
  const description =
    'Un sistema avanzato, altamente personalizzabile per la gestione delle code per la tua attività'
  return (
    <>
      <DefaultSeo
        title={title}
        description={description}
        titleTemplate={`%s | ${description}`}
        openGraph={{
          type: 'website',
          locale: 'it_IT',
          url: 'https://www.gaudiaweb.com',
          site_name: 'Gaudia',
          description,
          images: [{ url: 'https://www.gaudiaweb.com/images/gaudia-logo.png' }],
        }}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon-gaudia-32x32.png',
            sizes: '32x32',
          },
          {
            rel: 'icon',
            href: '/favicon-gaudia-192x192.png',
            sizes: '192x192',
          },
        ]}
      />

      <ChakraProvider resetCSS theme={theme}>
        <ColorModeProvider
          options={{
            useSystemColorMode: true,
          }}
        >
          <Component {...pageProps} />
        </ColorModeProvider>
      </ChakraProvider>
    </>
  )
}
